import React from 'react';
import { toast as toastToastify, ToastContainer as ToastContainerToastify } from 'react-toastify';
import styled from 'styled-components';
import Flex from '../../styles/Flex';
import Text from '../../styles/Text';
import { Check } from '../Icon/16x16';

export const ToastContainer = styled(ToastContainerToastify).attrs({
  // custom props
  hideProgressBar: true
})`
  .Toastify__toast--success {
    background-color: #3498dc;
    min-height: 55px;
  }
  .Toastify__close-button {
    align-self: center;
    > svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const toast = {
  success: (message: string) =>
    toastToastify.success(
      <Flex gap="sp16">
        <Check color="borderLight1" />
        <Text.SmallParagraph weight={900} color="light">
          {message}
        </Text.SmallParagraph>
      </Flex>
    )
};
